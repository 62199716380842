@use "../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 18px;
}

.subtitle {
  color: $dark-gray;
  text-align: center;
  width: 230px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-bottom: 30px;
}

.button {
  width: 100%;
}

@media (max-width: 768px) {
  .title {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  .form {
    width: 330px;
    margin-bottom: 24px;
  }

  .input {
    width: 100%;
    margin-bottom: 24px;
  }
}
