@use "../../ui-kit/scss/variables.scss" as *;

.body {
  margin: 100px 0 60px;
  max-width: 1028px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;

  font-size: 20px;
  line-height: 28px;
  font-weight: 600;

  margin-bottom: 8px;

  &__icon {
    margin-right: 8px;

    &--green {
      & > path {
        fill: $green;
      }
    }

    &--red {
      & > path {
        fill: $red;
      }
    }
  }
}

.forms {
  display: flex;
  flex-direction: column;
  padding: 31px 36px;
}

.form {
  width: 100%;
  margin-bottom: 12px;

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 18px;
  }

  &__item {
    width: 100%;
    height: 48px;
    & + & {
      margin-top: 18px;
    }
  }
}

.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.form-col {
  display: flex;
  flex-direction: column;

  width: calc(50% - 18px);

  &__input {
    background-color: $black-bg;
  }
}

@media (max-width: 768px) {
  .body {
    margin: 44px 0 130px;
  }

  .header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .title {
    align-self: flex-start;
    margin-bottom: 40px;
  }

  .forms {
    padding: 24px;
  }

  .form-row {
    flex-direction: column;
  }

  .form-col {
    width: 100%;
    & + & {
      margin-top: 16px;
    }
  }
}
