@use "../../scss/variables.scss" as *;

.wrapper {
  border-radius: 8px;
}

.textarea {
  padding: 13px 16px;
  background-color: $black-element-bg;
  resize: none;
  border-radius: 8px;

  width: 100%;
  height: 100%;

  font-size: 18px;
  line-height: 22px;

  &::placeholder {
    color: $dark-gray;
    font-weight: 500;
  }
}
