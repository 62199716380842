@use "../../ui-kit/scss/variables.scss" as *;

.body {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 12px;
}

.subtitle {
  color: $dark-gray;
  text-align: center;
  width: 340px;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 30px;
}
