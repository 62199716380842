@use "../../ui-kit/scss/variables.scss" as *;

.body {
  margin: 100px 0 60px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}

.form {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 18px;
  }

  &__wrapper {
    padding: 30px 36px;
    max-width: 532px;
  }

  &__input {
    background-color: $black-bg;
    font-size: 18px;
    line-height: 22px;

    &-wrapper {
      width: 100%;

      & + & {
        margin-top: 18px;
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .body {
    margin-bottom: 130px;
  }

  .title {
    margin-bottom: 40px;
  }

  .form {
    &__title {
      font-weight: 500;
    }

    &__wrapper {
      padding: 20px 24px;
    }
  }
}
