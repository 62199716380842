.wrapper {
  position: relative;
}

.icon {
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
}
