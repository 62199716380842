@use "../../../ui-kit/scss/variables.scss" as *;

.table {
  display: flex;
  flex-direction: column;
}

.body {
  margin-bottom: 16px;
}

.head {
  margin-bottom: 24px;
}

.button {
  transition: all ease-out 0.1s;

  cursor: pointer;
  width: 150px;
  height: 45px;
  background-color: $black-element-bg;
  border-radius: 8px;

  font-size: 16px;
  line-height: 19px;

  color: $dark-gray;
  margin: 0 auto;
  align-self: center;

  &:hover {
    background-color: $green-hover;
    box-shadow: 0px 0px 40px rgba(0, 192, 90, 0.35);
    color: #f9f9f9;
  }
}
