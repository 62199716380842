$green: #00c05a;
$green-hover: #00a54d;
$green-active: #00863f;
$green-text: #1eb224;

$red: #d43f32;

$black-bg: #292d3f;
$black-element-bg: #303445;
$black-element-bg-hover: #3e445480;

$dark-gray: #677481;

$gold: #fcc958;
$gold-hover: #ffb40d;
