@use "../../../ui-kit/scss/variables.scss" as *;

.wrapper {
  padding: 18px 16px;
  min-width: 195px;
  max-width: 195px;

  & + & {
    margin-left: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 16px;
}

.balance {
  text-align: center;
  font-weight: 500;
  margin-bottom: 16px;
}

.form {
  width: 100%;
  &__item {
    & + & {
      margin-top: 16px;
    }
  }

  &__input-label {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;
    color: $dark-gray;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__input {
    background-color: $black-bg;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  &__button {
    width: 100%;
    height: 47px;
    margin-top: 24px;
  }
}
