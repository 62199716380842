@use "../../scss/variables.scss" as *;

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 57px;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 192, 90, 0.35);
  background-color: $green;

  font-size: 18px;
  line-height: 22px;
  font-weight: bold;

  cursor: pointer;

  transition: all ease-out 0.1s;

  &:hover {
    background-color: $green-hover;
  }

  &:active {
    background-color: $green-active;
  }

  &--sm {
    width: 150px;
    height: 45px;

    font-size: 16px;
    line-height: 19px;
  }

  &--muted {
    background-color: #63a481;
  }
}
