@use "../../../ui-kit/scss/variables.scss" as *;

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
  margin-bottom: 40px;
}

.items {
  display: flex;
  width: calc(100% - 60px);

  & + & {
    margin-top: 90px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    margin-left: 100px;
  }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
  }

  &__title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__text {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    color: $dark-gray;
  }
}

@media (max-width: 768px) {
  .items {
    flex-direction: column;
  }

  .item {
    & + & {
      margin-left: 0;
      margin-top: 40px;
    }
  }
}
