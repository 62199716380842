@use "../../scss/variables.scss" as *;

.gold {
  color: $gold;
}

.green {
  color: $green;
}

.red {
  color: $red;
}

.dark-green {
  color: $green-text;
}

.dark-gray {
  color: $dark-gray;
}

.white {
  color: #fff;
}

.blue {
  color: rgb(0,0,200);
}
