@use "../../ui-kit/scss/variables.scss" as *;

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.logo {
  margin-right: 95px;
}

.nav {
  display: flex;
  align-items: center;
  flex: 1 0 auto;

  &__item {
    & + & {
      margin-left: 32px;
    }

    &--active {
      color: $green;
    }
  }
}

.menus {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
}

@media (max-width: 768px) {
  .header {
    align-items: center;
    padding: 16px 0;
  }
}
