.wrapper {
  margin-bottom: 40px;
}

.controls {
  margin-bottom: 24px;
}

.graph {
  height: 360px;
}

.graphpadding {
  padding: 15px;
}
