@use "../../ui-kit/scss/variables.scss" as *;
@use "../../ui-kit/scss/classes.scss" as *;

.body {
  margin: 100px 0 60px;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;

  &__inputs {
    display: flex;
  }

  &__button {
    width: 460px;
    margin-top: 24px;
  }
}

.input-list {
  width: 460px;
}

.input {
  width: 100%;

  &__input {
    font-size: 18px;
    line-height: 22px;
  }

  & + & {
    margin-top: 16px;
  }
}

.textarea {
  flex: 1 0 auto;
  margin-left: 32px;
  height: 116px;
}

.table {
  margin-top: 12px;
  padding: 0 12px;

  &__row {
    padding: 12px 0;
    align-items: center;

    & + & {
      border-top: 1px solid rgba(103, 116, 129, 0.1);
    }
  }

  &__bold-text {
    font-weight: 600;
  }
}

.requests {
  padding: 0 24px;
}

.request-item {
  padding: 24px 0;

  & + & {
    border-top: 1px solid rgba(103, 116, 129, 0.1);
  }

  &__date {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $dark-gray;
    text-align: right;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    font-size: 18px;
    line-height: 22px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 47px;
    margin-top: 24px;
  }
}

.hide-button {
  visibility: hidden;
}

@media (max-width: 768px) {
  .body {
    width: 100%;
    margin-top: 44px;
    margin-bottom: 130px;
  }

  .title {
    margin-bottom: 40px;
  }

  .form {
    &__inputs {
      flex-direction: column;
    }

    &__button {
      width: 100%;
    }
  }

  .input-list {
    width: 100%;
  }

  .textarea {
    margin-left: 0;
    margin-top: 12px;
    height: 236px;
  }
}
