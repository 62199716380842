@use "../../scss/variables.scss" as *;

.checkbox {
  cursor: pointer;
  &--red {
    & > rect {
      fill: $red;
    }
  }

  &--green {
    & > rect {
      fill: $green;
    }
  }
}
